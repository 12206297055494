<template>
    <div class="information_page">
        <new-header :config="headerConfig" @onRight="headerChange"></new-header>

        <div class="form-list">
            <van-cell-group>
                <van-cell class="cell" is-link title="头像">
                    <template #default>
                        <van-uploader :readonly="changeFlag" :max-count="1" accept="image/*" :before-read="beforeRead"
                            :after-read="e => afterRead(e, 'imgAvatar')">
                            <van-image round class="avator" fit="cover" :src="form1.user_avatar" />
                        </van-uploader>
                    </template>
                </van-cell>
            </van-cell-group>
            <van-form input-align="right" error-message-align="right" ref="Form1">
                <van-field :readonly="changeFlag" v-model="form1.user_name" name="user_name" label="昵称" />
            </van-form>
        </div>

        <div class="form-title">个人基本信息</div>
        <div class="form-list">
            <van-form input-align="right" error-message-align="right" ref="Form2">
                <van-field :readonly="changeFlag" v-model="form2.username" label="姓名" placeholder="暂无" />
                <van-field readonly v-model="form2.sex" name="sex" label="性别" placeholder="暂无"
                    @click="showPickerChange('sex')" />
                <van-field :readonly="changeFlag" v-model="form2.nation" label="民族" placeholder="暂无" />
                <van-field readonly v-model="form2.politics" label="政治面貌" placeholder="暂无"
                    @click="showPickerChange('politics')" />
                <van-field :readonly="changeFlag" v-model="form2.identity" label="身份证号" placeholder="暂无" />
                <van-field readonly v-model="form2.education" label="文化程度" placeholder="暂无"
                    @click="showPickerChange('education')" />
                <van-field :readonly="changeFlag" v-model="form2.address" label="当前居住地" placeholder="暂无" rows="1"
                    autosize type="textarea" />
                <van-field readonly v-model="form2.phone" label="联系方式" placeholder="暂无"
                    @click="showDialogChange('form2')" />
            </van-form>
        </div>

        <!-- 未成为工分会员不展示 -->
        <div v-if="isPointsVip">
            <div class="form-title">工分合同信息</div>
            <div class="form-list list3">
                <van-form input-align="right" error-message-align="right" ref="Form3">
                    <van-field :readonly="changeFlag" v-model="form3.name" label="姓名" />
                    <van-field :readonly="changeFlag" v-model="form3.Idcard" label="身份证号" />
                    <van-field :readonly="changeFlag" v-model="form3.address" label="当前居住地" rows="1" autosize
                        type="textarea" />
                    <van-field readonly v-model="form3.phone" name="phone" label="联系方式"
                        @click="showDialogChange('form3')" />
                </van-form>
            </div>
        </div>

        <!-- 保存按钮 -->
        <div class="bottom_div">
            <van-button v-if="!changeFlag" round type="primary" block class="btn" color="#2B2828"
                @click="submit">保存</van-button>
        </div>

        <!-- 选择器 -->
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="pickerList" @confirm="onConfirm" @cancel="showPicker = false" />
        </van-popup>

        <!-- 手机号换绑提示 -->
        <new-dialog :config="dialogConfig" @confirm="dialogConfirmChange"></new-dialog>

        <!-- 手机号获取验证码 -->
        <van-action-sheet v-model="showPhone" title="更换手机号">
            <div class="page_form">
                <div class="form_item">
                    <input class="value_input" v-model="form.account" placeholder="请输入手机号" />
                </div>
                <div class="form_item">
                    <input class="value_input" v-model="form.code" placeholder="请输入验证码" />
                    <div class="item_btn" @click="getCaptcha">
                        <van-count-down class="count" v-if="codeTime" :time="codeTime" format="ss 秒"
                            @finish="codeTime = null" />
                        <span v-else>获取验证码</span>
                    </div>
                </div>
                <div class="page_btn">
                    <van-button type="primary" block color="#2B2828" round class="btn"
                        @click="codeSubmit">确定</van-button>
                </div>
            </div>
        </van-action-sheet>

    </div>
</template>
<script>
import newDialog from '@/components/newDialog.vue';
import newHeader from "@/components/newHeader";

import captcha from '@/utils/captcha';
import valid from '@/utils/valid';
import * as apiCommon from "@/api/common";

import { getImgToken, uploadImg } from "@/api/common"
import { getUserData_api, changeUserInfo_api } from '@/api/perdata'

export default {
    components: {
        newHeader,
        newDialog
    },
    data() {
        return {
            headerConfig: {
                show: true,
                title: '个人信息',
                rightText: '修改',
                rightColor: '#0169FF'
            },
            dialogConfig: {
                showDialog: false,
                text: '确定更换当前已绑定手机号吗？',
                textAlign: 'center',
                cancelText: '考虑考虑',
                confirmText: '确认',
            },
            changeFlag: true, // 是否能够修改信息
            isPointsVip: false, // 是否为工分会员

            form1: {},
            form2: {},
            form3: {},
            code_2: '',
            code_3: '',
            userData: {}, // 基本信息
            shareData: {}, // 工分信息
            rulesErr_1: {
                username: '姓名未填写',
                sex: '性别未选择',
                nation: '民族未填写',
                politics: '政治面貌未选择',
                identity: '身份证未填写',
                education: '文化程度未选择',
                address: '当前居住地未填写',
                phone: '联系方式未填写',
            },
            rulesErr_2: {
                name: '工分信息姓名未填写',
                Idcard: '工分信息身份证未填写',
                address: '工分信息当前居住地未填写',
                phone: '工分信息联系方式未填写',
            },

            imgAvatar: {
                value: [],
                cdUrl: "https://cdn.health.healthplatform.xyz/",
                token: null,
                config: null,
            },

            phoneDialogType: '',
            showPhone: false,
            form: { account: '', code: '' },
            codeTime: null,

            showPicker: false,
            pickType: '',
            pickerList: [],
            sex: ['男', '女'],
            politics: ["群众", "团员", "党员"],
            education: ["初中", "高中", "专科", "本科", "研究生", '其他'],
        }
    },
    created() {
        this.getUploadToken()
        this.getUserData()
    },
    methods: {
        // 获取用户信息
        async getUserData() {
            this.changeFlag = false
            let res = await getUserData_api()
            if (res.code == 0) {
                let data = res.data
                // 微信绑定信息
                this.form1 = {
                    user_avatar: data.user_avatar,
                    user_name: data.user_name
                }
                // 基本信息
                let userData = data.union_info ? data.union_info : {}
                this.userData = userData
                this.form2 = {
                    username: userData.username,
                    sex: userData.sex,
                    nation: userData.nation,
                    politics: userData.politics,
                    identity: userData.identity,
                    education: userData.education,
                    address: userData.address,
                    phone: userData.phone,
                }
                // 工分会员信息
                this.isPointsVip = data.union_shareholder_info ? true : false
                let shareData = data.union_shareholder_info ? data.union_shareholder_info : {}
                this.shareData = shareData
                this.form3 = {
                    name: shareData.name,
                    Idcard: shareData.Idcard,
                    phone: shareData.phone,
                    address: shareData.address,
                }
                this.changeFlag = true
            }
        },
        // 修改信息
        headerChange() {
            if (this.changeFlag) {
                this.changeform(this.form1)
                this.changeform(this.form2)
                this.changeform(this.form3)

                this.headerConfig.rightText = '取消'
                this.headerConfig.rightColor = 'red'
                this.changeFlag = false
            } else {
                this.getUserData()

                this.headerConfig.rightText = '修改'
                this.headerConfig.rightColor = '#0169FF'
                this.changeFlag = true
            }
        },
        // 表单回显信息
        changeform(data) {
            Object.keys(data).forEach(item => {
                if (data[item] == '暂无') data[item] = ''
            })
        },
        // 选择器事件
        showPickerChange(type) {
            if (!this.changeFlag) {
                this.showPicker = true
                this.pickType = type
                this.pickerList = this[type]
            }
        },
        onConfirm(val) {
            this.form2[this.pickType] = val
            this.showPicker = false
        },

        async submit() {
            let message = ''
            message = this.rulesChange(this.form2, this.rulesErr_1)
            if (Object.keys(this.form2).length == 0) return this.$toast('请填写个人基本信息')
            
            if (this.isPointsVip) {
                message = this.rulesChange(this.form3, this.rulesErr_2)
                if (Object.keys(this.form3).length == 0) return this.$toast('请填写工分合同信息')
                if (!(/^[A-Za-z0-9]+$/).test(this.form3.Idcard)) message = '请填写正确的身份证号'
            }
            if (message) return this.$toast(message)

            let data = {}
            data.user_avatar = this.form1.user_avatar
            data.user_name = this.form1.user_name
            // 判断手机号和身份证是否修改
            data.union_info = JSON.parse(JSON.stringify(this.form2))
            if (this.userData.phone != this.form2.phone) {
                data.union_info.phone = this.form2.phone
                data.union_code = this.code_2
            } else {
                data.union_info.phone = this.userData.user_phone
            }
            if (this.userData.identity != this.form2.identity) {
                data.union_info.identity = this.form2.identity
            } else {
                data.union_info.identity = this.userData.user_identity
            }
            if (!(/^[A-Za-z0-9]+$/).test(data.union_info.identity)) return this.$toast('请填写正确的身份证号')

            // 工分信息判断
            if (this.isPointsVip) {
                data.share_info = JSON.parse(JSON.stringify(this.form3))
                // 判断手机号是否修改
                data.share_info.phone = this.form3.phone
                data.share_info.Idcard = this.form3.Idcard
                if (this.shareData.phone != this.form3.phone) {
                    data.share_code = this.code_3
                }
            }

            let res = await changeUserInfo_api(data)
            if (res.code == 0) {
                this.changeFlag = true
                this.headerConfig.rightText = '修改'
                this.headerConfig.rightColor = '#0169FF'
                this.$toast.success('修改成功')
            } else {
                this.$toast.success('修改失败')
            }
        },
        // 验证
        rulesChange(data, rules) {
            let message = ''
            Object.keys(rules).forEach(item => {
                if (!data[item]) message = rules[item]
            })
            return message
        },
        // 手机号更换提示弹窗
        showDialogChange(type) {
            if (!this.changeFlag) {
                this.code_2 = '', this.code_3 = ''
                this.phoneDialogType = type
                if (type == 'form2') {
                    if (!this.userData.phone) {
                        this.dialogConfig.text = '首次绑定需要发送验证码，确认绑定吗？'
                    } else {
                        this.dialogConfig.text = '确定更换当前已绑定手机号吗？'
                    }
                }
                this.dialogConfig.showDialog = true
            }
        },
        dialogConfirmChange() {
            this.dialogConfig.showDialog = false
            this.showPhone = true
        },

        // 获取滑动验证码
        async getCaptchaInfo() {
            let res = await captcha.getCaptcha();
            if (res) {
                this.captcha = res;
                this.timeter()
            }
        },
        // 获取短信验证码
        async timeter() {
            let param = {}, captcha = this.captcha;

            param.mobile = this.form.account;
            param.randstr = captcha.randstr;
            param.ticket = captcha.ticket;

            let res = await apiCommon.getSmsCode(param);
            this.captcha = null;
            this.codeTime = 60 * 1000
            if (!res || typeof res === 'string' || res.error) {
                this.$toast(res ? res.error || res : '获取验证码失败!')
                this.codeTime = null
            }
        },
        getCaptcha() {
            if (!this.form.account) return this.$toast('请先填写手机号');
            if (!valid.isMobile(this.form.account)) return this.$toast('请输入正确的手机号');
            this.getCaptchaInfo();
        },
        codeSubmit() {
            let { account, code } = this.form
            if (account && code) {
                this.showPhone = false
                // 区分基本-工分信息手机号
                if (this.phoneDialogType == 'form2') {
                    this.form2.phone = account
                    this.code_2 = code
                } else {
                    this.form3.phone = account
                    this.code_3 = code
                }
            } else {
                this.$toast('请填写完整信息')
            }
        },

        //获取七牛云上传token
        async getUploadToken() {
            let res = await getImgToken()
            if (!res || typeof res === "string" || res.error) return this.$toast(res ? res.error || res : "获取七牛云信息失败!");
            this.imgAvatar.token = res.data;
            this.imgAvatar.config = "{ useCdnDomain: true }"
        },
        //上传
        async Upload(type, file) {
            let param = new FormData();
            param.append("token", this[type].token);
            param.append("config", this[type].config);
            param.append("file", file);

            let res = await uploadImg(param);
            let imgUrl = this[type].cdUrl + res.key;
            this[type].value = [{ url: imgUrl, isImage: true }]

            // 图片地址赋值
            this.form1.user_avatar = imgUrl
        },
        // 文件读取前触发
        beforeRead(e) {
            if (e.size > 10 * 1024 * 1024) {
                this.$toast('文件大小不能超过10M')
                return false
            }
            return true
        },
        //文件读取后触发
        afterRead(e, type) {
            this.Upload(type, e.file)
        },
    }

}
</script>
<style scoped lang="css">
.information_page {
    box-sizing: border-box;
    background-color: #F9F9FB;
    min-height: 100vh;
    width: 100%;
}

.form-list {
    padding: 10px;
    box-sizing: border-box;
    background-color: #FFFFFF;
}

.form-title {
    font-size: 28px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 80px;
    margin-left: 40px;
}

.van-cell-group>>>.van-cell--clickable {
    align-items: center;
}

.avator {
    width: 50px;
    height: 50px;
    margin-top: 5px;
}

.bottom_div {
    box-sizing: border-box;
    padding: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    height: 70px;
}

.page_form {
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.form_item {
    border: 1px solid #DCDEE0;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    height: 90px;
}

.value_input {
    width: 100%;
    height: 60px;
    border: none;
    background-color: inherit;
    font-size: 32px;
}

.item_btn {
    width: 200px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #DCDEE0;
    font-size: 28px;
    text-align: center;
    color: #0169FF;
}

.count {
    font-size: 28px;
}

.page_btn {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.page_btn>.btn {
    height: 75px;
}


.uploader_btn {
    display: flex;
    justify-content: center
}
</style>